import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Logo from '../components/logo'
import Intro from '../components/intro'
import Stoc from '../components/stoc'
import Info from '../components/info'
import Contact3 from '../components/contact3'
import Partener from '../components/partener'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>CarHub Trade</title>
        <meta property="og:title" content="CarHub Trade" />
      </Helmet>
      <Logo rootClassName="logoroot-class-name"></Logo>
      <Intro
        text2={
          <Fragment>
            <span className="home-text10">
              Gasim masina perfecta pentru tine
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="home-text11">
              Serviciul tau de incredere pentru achizitionarea masinii preferate
            </span>
          </Fragment>
        }
      ></Intro>
      <Stoc rootClassName="stocroot-class-name"></Stoc>
      <Info
        step1Title={
          <Fragment>
            <span className="home-text12">Consignatie</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text13">Comanda</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text14">Finantare</span>
          </Fragment>
        }
        step3Title1={
          <Fragment>
            <span className="home-text15">Rent a Car</span>
          </Fragment>
        }
        rootClassName="inforoot-class-name"
        step1Description={
          <Fragment>
            <span className="home-text16">
              Valorifica-ti automobilul eficient: consignatia noastra iti
              garanteaza vanzare rapida si sigura.
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text17">
              Daca nu ai gasit in stocul nostru masina cautata, ti-o putem aduce
              la comanda.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text18">
              Acceseaza finantare avantajoasa pentru masina dorita: solutii
              rapide, personalizate si accesibile.
            </span>
          </Fragment>
        }
        step3Description1={
          <Fragment>
            <span className="home-text19">
              Daca ai nevoie de o masina temporar, iti oferim servicii de
              inchiriere auto.
            </span>
          </Fragment>
        }
      ></Info>
      <Contact3></Contact3>
      <Partener rootClassName="partenerroot-class-name"></Partener>
    </div>
  )
}

export default Home
