import React from 'react'

import PropTypes from 'prop-types'

import './partener.css'

const Partener = (props) => {
  return (
    <div
      className={`partener-layout251 thq-section-padding ${props.rootClassName} `}
    >
      <div className="partener-container">
        <div className="partener-actions">
          <button className="partener-button thq-button-filled">
            <a
              href="https://www.promotors.ro/"
              target="_blank"
              rel="noreferrer noopener"
              className="partener-action1 thq-body-small"
            >
              Partener ProMotors
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

Partener.defaultProps = {
  rootClassName: '',
}

Partener.propTypes = {
  rootClassName: PropTypes.string,
}

export default Partener
