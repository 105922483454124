import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './info.css'

const Info = (props) => {
  return (
    <div
      className={`info-layout251 thq-section-padding ${props.rootClassName} `}
    >
      <div className="info-max-width thq-section-max-width">
        <div className="info-container1">
          <div className="info-container2 thq-card">
            <img
              alt={props.imageAlt2}
              src={props.imageSrc2}
              className="info-image1"
            />
            <h2 className="info-text10 thq-heading-2">
              {props.step1Title ?? (
                <Fragment>
                  <span className="info-text20">Consignatie</span>
                </Fragment>
              )}
            </h2>
            <span className="info-text11 thq-body-small">
              {props.step1Description ?? (
                <Fragment>
                  <span className="info-text25">
                    Valorifica-ti automobilul eficient: consignatia noastra iti
                    garanteaza vanzare rapida si sigura.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="info-container3 thq-card">
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              className="info-image2"
            />
            <h2 className="info-text12 thq-heading-2">
              {props.step2Title ?? (
                <Fragment>
                  <span className="info-text18">Comanda</span>
                </Fragment>
              )}
            </h2>
            <span className="info-text13 thq-body-small">
              {props.step2Description ?? (
                <Fragment>
                  <span className="info-text22">
                    Daca nu ai gasit in stocul nostru masina cautata, ti-o putem
                    aduce la comanda.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="info-container4 thq-card">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="info-image3"
            />
            <h2 className="thq-heading-2">
              {props.step3Title ?? (
                <Fragment>
                  <span className="info-text21">Finantare</span>
                </Fragment>
              )}
            </h2>
            <span className="info-text15 thq-body-small">
              {props.step3Description ?? (
                <Fragment>
                  <span className="info-text19">
                    Acceseaza finantare avantajoasa pentru masina dorita:
                    solutii rapide, personalizate si accesibile.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="info-container5 thq-card">
            <img
              alt={props.imageAlt4}
              src={props.imageSrc4}
              className="info-image4"
            />
            <h2 className="info-text16 thq-heading-2">
              {props.step3Title1 ?? (
                <Fragment>
                  <span className="info-text24">Rent a Car</span>
                </Fragment>
              )}
            </h2>
            <span className="info-text17 thq-body-small">
              {props.step3Description1 ?? (
                <Fragment>
                  <span className="info-text23">
                    Daca ai nevoie de o masina temporar, iti oferim servicii de
                    inchiriere auto.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Info.defaultProps = {
  step2Title: undefined,
  imageAlt2: 'image',
  step3Description: undefined,
  imageSrc2: '/icons/trade-200h.png',
  imageAlt1: 'image',
  step1Title: undefined,
  step3Title: undefined,
  step2Description: undefined,
  rootClassName: '',
  step3Description1: undefined,
  step3Title1: undefined,
  imageAlt4: 'image',
  imageSrc4: '/icons/rental-car-200h.png',
  step1Description: undefined,
  imageSrc1: '/icons/trailer-200h.png',
  imageSrc: '/icons/income-200h.png',
  imageAlt: 'image',
}

Info.propTypes = {
  step2Title: PropTypes.element,
  imageAlt2: PropTypes.string,
  step3Description: PropTypes.element,
  imageSrc2: PropTypes.string,
  imageAlt1: PropTypes.string,
  step1Title: PropTypes.element,
  step3Title: PropTypes.element,
  step2Description: PropTypes.element,
  rootClassName: PropTypes.string,
  step3Description1: PropTypes.element,
  step3Title1: PropTypes.element,
  imageAlt4: PropTypes.string,
  imageSrc4: PropTypes.string,
  step1Description: PropTypes.element,
  imageSrc1: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default Info
