import React from 'react'

import PropTypes from 'prop-types'

import './logo.css'

const Logo = (props) => {
  return (
    <div className={`logo-container1 ${props.rootClassName} `}>
      <header className="logo-container2">
        <img
          alt="Company Logo"
          src="/external/logo-no-background1.svg"
          className="logo-image1"
        />
      </header>
    </div>
  )
}

Logo.defaultProps = {
  rootClassName: '',
}

Logo.propTypes = {
  rootClassName: PropTypes.string,
}

export default Logo
