import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './intro.css'

const Intro = (props) => {
  return (
    <div
      className={`intro-header30 thq-section-padding ${props.rootClassName} `}
    >
      <div className="intro-container">
        <h1 className="intro-text1 thq-heading-1">
          {props.text2 ?? (
            <Fragment>
              <span className="intro-text3">
                Gasim masina perfecta pentru tine
              </span>
            </Fragment>
          )}
        </h1>
        <p className="intro-text2 thq-body-large">
          {props.text3 ?? (
            <Fragment>
              <span className="intro-text4">
                Serviciul tau de incredere pentru achizitionarea masinii
                preferate
              </span>
            </Fragment>
          )}
        </p>
      </div>
    </div>
  )
}

Intro.defaultProps = {
  text2: undefined,
  text3: undefined,
  rootClassName: '',
}

Intro.propTypes = {
  text2: PropTypes.element,
  text3: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Intro
