import React from 'react'

import PropTypes from 'prop-types'

import './stoc.css'

const Stoc = (props) => {
  return (
    <div
      className={`stoc-layout251 thq-section-padding ${props.rootClassName} `}
    >
      <div className="stoc-container">
        <div className="thq-flex-row stoc-section-title">
          <div className="stoc-column thq-flex-column"></div>
        </div>
        <div className="stoc-actions">
          <button className="stoc-button thq-button-filled">
            <a
              href="https://promotorscluj.autovit.ro/inventory"
              target="_blank"
              rel="noreferrer noopener"
              className="stoc-action1 thq-body-small"
            >
              Stocul nostru
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

Stoc.defaultProps = {
  rootClassName: '',
}

Stoc.propTypes = {
  rootClassName: PropTypes.string,
}

export default Stoc
